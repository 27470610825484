<template>
  <div class="container">
    <div class="section">
      <div class="columns is-multiline">
        <div class="column is-12 title-section">
          <h1>Usuarios</h1>
          <button
            @click="openModal"
            class="button is-primary">Crear usuario</button>
        </div>
        <div class="column is-12">
          <div class="card">
            <table class="table is-fullwidth is-narrow is-hoverable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Correo electrónico</th>
                  <th>Estatus</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading">
                  <tr v-for="el in data" :key="el.id">
                    <td>
                      {{el.id}}
                    </td>
                    <td>
                      <router-link :to="`/usuario/detalle/${el.id}`">
                        {{el.name}}
                      </router-link>
                    </td>
                    <td>
                      {{el.email}}
                    </td>
                    <td>
                      {{el.status ? 'Activo' : 'Inactivo'}}
                    </td>
                  </tr>
                </template>
                <tr v-if="data.length === 0 && !loading">
                  <td colspan="4" class="has-text-centered">
                    Sin información
                  </td>
                </tr>
                <tr v-if="loading">
                  <td colspan="4" class="has-text-centered is-loading">
                    <div class="lds-dual-ring" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal-nuevo-usuario
      v-if="modal"
      :updateList="getUsers"
      :close="openModal"
    />
  </div>
</template>

<script>
import api from '@/libs/api'
export default {
  name: 'Usuarios',
  components: {
    ModalNuevoUsuario: () => import('@/components/usuarios/ModalNuevoUsuario')
  },
  data () {
    return {
      modal: false,
      loading: true,
      data: []
    }
  },
  methods: {
    addUser () {},
    openModal () {
      this.modal = !this.modal
    },
    async getUsers (page) {
      const response = await api.getUsuarios(page)
      if (response.success) {
        this.data = response.payload
      }
    }
  },
  mounted () {
    this.loading = true
    this.getUsers(1)
      .then(resp => {
        this.loading = false
      })
  }
}
</script>

<style scoped>
</style>
